#course-enroll {
    position: relative;
}

#course-enroll .head-content {
    width: 45% !important;
}

#course-enroll .masthead .row {
    height: 100%;
}

#course-enroll .head-content,
#course-enroll .head-content-2 {
    margin-top: -265px;
}

#course-enroll header {
    margin-bottom: 65rem;
}

#course-enroll .form-section {
    position: absolute;
    top: 60%;
    z-index: 1;
    left: 0;
    right: 0;
}

#course-enroll form {
    background: white;
    max-width: 100%;
}

#course-enroll .form-container {
    padding: 0 0;
}

@media (max-width: 991px) {
    #course-enroll .masthead .row {
        height: unset;
        padding: 3rem 0;
    }

    #course-enroll .form-container {
        padding: 0;
    }

    #course-enroll header {
        margin-bottom: 75rem;
    }

    #course-enroll .head-content {
        margin-top: 8px;
    }

    #course-enroll .head-content-2 {
        margin-top: 15px;
    }

    #course-enroll .head-content {
        width: 100% !important;
    }

    #course-enroll .form-section {
        top: 70%;
    }
}

@media (max-width: 768px) {
    #course-enroll .masthead {
        height: fit-content !important;
        padding: 3.5rem 0;
    }

    #course-enroll header {
        margin-bottom: 105rem;
    }

    #course-enroll .form-section {
        top: 95%;
    }

    #course-enroll .form-section .row {
        padding: 0 .5rem;
    }
}

@media (max-width: 576px) {
    #course-enroll .masthead {
        padding: 3.5rem 1rem;
    }

    #course-enroll .masthead .row {
        height: unset;
        padding: 0 0;
    }
}