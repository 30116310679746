.float-buttons {
    position: fixed;
    top: 50%;
    left: 0;
    text-align: right;
    z-index: 9999;
    margin-top: -55px;
    display: flex;
    flex-direction: column;
}

.float-buttons ul {
    padding: 0;
    margin: 0;
}

.float-buttons ul li {
    margin-left: -170px;
    transition: all 0.5s ease-in-out;
}

.float-buttons ul li:hover {
    margin-left: 0;
    transition: all 0.5s ease-in-out;
}

.float-buttons a.btn.request-btn-2,
.float-buttons a.btn.resume-btn-2,
.float-buttons a.btn.video-btn {
    width: 227px;
    padding: 15px 20px;
    text-transform: capitalize;
    border-radius: 0 10px 10px 0;
}

.float-buttons a.btn.request-btn-2 {
    background-color: var(--sea-blue);

}

.float-buttons a.btn.resume-btn-2 {
    background-color: var(--dark-blue);
    color: var(--color-white);
    font-size: 15.5px;
}

.float-buttons a.btn.video-btn {
    background-color: var(--yellow-color);
    color: var(--black-color);
    font-size: 15.5px;
}

@keyframes rotate360 {
    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation: 2s rotate360 infinite linear;
}