.mission-icon {
    width: 59.89px;
    height: 54.66px;
}

.safe-box {
    width: 252px;
    height: 95px;
    position: relative;
}

.safe-box img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 19px 37px rgba(86, 86, 86, 0.25);
    bottom: 0.75em;
}

#services .section-img {
    width: 80%;
}

#services p {
    margin-bottom: 1rem !important;
}

@media (max-width: 768px) {
    #services .section-img {
        width: 100%;
    }

}