footer {
    min-height: 100vh;
    background-color: var(--dark-blue);
    position: relative;
    color: var(--color-light);
}

footer .container .formWrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    /* align-items: center;
justify-content: center; */
}

footer .container .formWrapper .header h1 {
    color: var(--sea-blue);
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    text-transform: capitalize;
    margin-bottom: 2rem;
}

footer .container .formWrapper .formContainer form .formGroup {
    display: flex;
    width: 100%;
    margin-bottom: 1.2rem;
    gap: 5%;
    /* border: solid red 2px; */
}

footer .container .formWrapper .formContainer form .formGroup div {
    flex: 1;
}

footer .container .formWrapper .formContainer form .formGroup input {
    border: solid #F0EFF2 1px;
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.3rem;
    background-color: #F0EFF2;
    color: black;
}

.fileSubmitGroup {
    justify-content: space-between;
}

input::file-selector-button {
    font-weight: bold;
    color: var(--dark-blue);
    padding: 0.5em;
    border: thin solid var(--sea-blue);
    border-radius: 3px;
    background-color: var(--sea-blue);
}

footer .container .formWrapper .formContainer form .formGroup input[type='file'] {
    background-color: transparent;
    border: 0;
    width: fit-content;
    color: var(--color-light);
    font-size: 13.5px !important;
}


footer .container .formWrapper .formContainer form .formGroup input[type='submit'] {
    min-width: 10rem;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    background: var(--sea-blue);
    color: var(--dark-blue);
    font-weight: 500;
    cursor: pointer;
    transition: all 400ms ease;
    border: 0;
    font-size: 13.5px !important;
    max-height: 3rem;
}

.menuContainer {
    display: flex;
    gap: 5%;
    padding: 3rem 0;
}

.menuContainer .col {
    flex: 1;
    /* width: 25%; */
}

.menuContainer .logoCol .logoWrapper {
    height: 10rem;
    width: 10rem;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 2rem;
}

.menuContainer .linksCol .header h4 {
    color: var(--sea-blue);
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.menuContainer .linksCol .permalinks {
    display: flex;
    flex-direction: column;
}

.menuContainer .linksCol .permalinks a {
    color: var(--color-light);
    text-transform: capitalize;
    margin: .3rem 0;
}

.menuContainer .linksCol .permalinks a:hover {
    color: var(--sea-blue);
}

footer .container .footer-menu {
    border-top: solid var(--color-light) 1px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    text-transform: capitalize;
    font-size: 0.9rem;
    padding: 1rem 0;
}

footer .container .footer-menu .copyright {
    width: 35%;
    justify-content: start;
}

footer .container .footer-menu .copyright .copy-name {
    color: var(--color-light);
}

footer .container .footer-menu .copyright .copy-name a {
    color: var(--color-light);
}

footer .container .footer-menu .copyright .top ul {
    display: flex;
    gap: 5%;
}

footer .container .footer-menu .copyright .top ul li a {
    color: var(--color-light);
}

/* footer .container .footer-menu .copyright .top ul li a:hover {
    color: var(--sea-blue);
} */

footer .container .footer-menu .copyright .bottom li a {
    color: var(--color-light);
}

/* footer .container .footer-menu .copyright .bottom a:hover {
    color: var(--sea-blue);
} */

footer .container .footer-menu .menu-items {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5%;
    place-items: center;
    /* border: solid red 2px; */
}

footer .container .footer-menu .menu-items .menu_item {
    color: var(--color-light);
    /* border: solid red 2px; */
}

footer a {
    text-decoration: none !important;
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {





    footer .container .formWrapper .formContainer form .formGroup {

        flex-direction: column;
        margin-bottom: 0;

    }



    footer .container .formWrapper .formContainer form .formGroup input {
        margin-bottom: 2rem;
    }




    footer .container .formWrapper .formContainer form .formGroup input[type='submit'] {
        width: 100%;

    }

    .menuContainer {
        flex-direction: column;
    }

    .menuContainer .col {
        flex: 1;
        /* width: 25%; */
    }

    .menuContainer .logoCol .logoWrapper {
        height: 10rem;
        width: 10rem;
        background-color: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 2rem;
    }

    .menuContainer .linksCol .header {
        margin-top: 2rem;
    }




    footer .container .footer-menu {
        flex-direction: column-reverse;
        margin-bottom: 4rem;
    }


    footer .container .footer-menu .copyright {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        margin-bottom: 6rem;
    }

    footer .container .footer-menu .copyright .top ul {
        flex-direction: column;
        margin: 2rem 0;
        /* border: solid red 2px; */
    }

    footer .container .footer-menu .menu-items {
        width: 100%;
        flex-direction: column;
        gap: 1.5rem;
        place-items: flex-start;
    }
}