.left {
    left: 3% !important;
    z-index: 10;
}

.right {
    right: 3% !important;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.btn.arrow {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--dark-blue);
    color: var(--color-white);
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 400ms ease;
    z-index: 1000;
}