@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid var(--color-light);
    /* Light grey */
    border-top: 10px solid var(--dark-blue);
    /* Black */
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 0;
    right: 0;
}