.masthead {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--dark-blue);
}

.masthead h1,
.masthead p {
    color: white;
}

.head-content {
    width: 80% !important;
}

@media (max-width: 768px) {
    .head-content {
        width: 100% !important;
    }

    .masthead {
        height: 300px !important;
    }
}