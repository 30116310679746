#request {
    position: relative;
}

#request header {
    margin-bottom: 40rem;
}

#request .head-content {
    margin-top: -100px;
}