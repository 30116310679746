#courses h5 {
    font-weight: 400;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}

#courses p.card-text {
    margin: 5px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: grey !important;
}