#get-started {
    height: 180px;
    background-image: url(../../assets/images/submit.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--dark-blue);
    color: #fff;
    margin-top: auto;
    margin-bottom: 1px;
}

#get-started-grey,
#get-started-custom {
    height: 180px;
    background-color: #BDBDBD;
    color: #fff;
    margin-top: auto;
    margin-bottom: 1px;
}

#get-started h2,
#get-started-grey h2,
#get-started-custom h2 {
    font-weight: 700 !important;
    font-size: 2rem !important;
}

#get-started-grey h2,
#get-started-custom h2 {
    color: var(--dark-blue);
}

@media (max-width: 1024px) {

    #get-started h2,
    #get-started-grey h2,
    #get-started-custom h2 {
        font-weight: 600 !important;
        font-size: 1.5rem !important;
    }
}