section h2 {
    text-align: unset;
}

#about .mission-title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
}

#about .mission-icon {
    width: 59.89px;
    height: 54.66px;
}

#about .solution-text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
}

#about .unique-content {
    column-gap: 15px;
    display: flex;
    align-items: center;
}

#about .unique-img {
    width: calc(100% / 2);
    border-radius: 15px;
}

#about .unique-img2 {
    width: 100%;
    border-radius: 15px;
}

#about .value-img-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

#about .value-img {
    width: 74px;
}

#about .card {
    border: none;
    height: 276px;
}

#about .card:hover {
    border: 1px solid #DCDCDC;
}

#about .empower-bg {
    height: 400px;
    background-image: url(../../assets/images/banner-section.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#empower {
    color: #fff;
    margin-bottom: 27rem;
}

#empower .emp-content {
    width: 70%;

}

#empower .play-btn {
    position: absolute;
    bottom: 0;
    background: white;
    height: 80px;
    width: 384px;
    margin-bottom: 31px;
    margin-left: 36px;
    border-radius: 100px;
}

#empower .play-icon {
    width: 23px;
}

.member-name {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
}

.member-title {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    margin-top: 0;
}

.member-img {
    width: 391.4px;
    height: 434.3px;
    margin-left: auto;
    margin-right: auto;
}

#team {
    margin-top: 34rem;
}

@media screen and (max-width: 1024px) {
    #team {
        margin-top: 0
    }

    #about .empower-bg {
        height: fit-content;
    }
}

@media screen and (max-width: 600px) {
    #team {
        margin-top: auto
    }

    #empower {
        margin-bottom: -3rem;
    }

    #about .mission-title {
        font-size: 28px;
        margin-top: 20px;
    }

    .member-img {
        width: 100%;
        height: 74%;
    }


}