.swiper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: relative
}

.swiper-slide {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 0px;
    /* height: 90vh; */
    display: flex;
    align-items: flex-end;
}

.swiper-wrapper {
    width: 100%;
    height: 95vh;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 100%;
    padding: 0 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slider-content h2 {
    margin: 0;
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
}

.slider-content p {
    margin: 16px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--sea-blue) !important;
}

.swiper-pagination-bullet {
    background-color: var(--sea-blue) !important;
}

.btn.slide-btn {
    border-radius: 20px;
    padding: 10px 35px;
    margin-top: 10px;
    background-color: var(--dark-blue);
    color: #fff;
}

.btn.slide-btn:hover {
    background-color: rgb(14, 41, 70);
    color: #fff;
}


.btn.slide-btn-2 {
    background-color: var(--sea-blue);
    color: var(--black-color);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {
    .slider-content {
        padding: 0 5rem;
    }

    .swiper-wrapper {
        height: 50vh;
    }

    .slider-content h2 {
        font-size: 2.5rem;
    }
}

/*============= MEDIA QUERIES (TABLET DEVICES) ===============*/

@media screen and (max-width: 768px) {
    .swiper-wrapper {
        height: 45vh;
    }
}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {

    .txtOverlay {
        padding: 20px 20px;

    }

    .swiper-slide .title {
        font-size: xx-large;
    }


    .swiper-slide .text {
        font-size: small;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 30px;
        height: 30px;
    }

    .slider-content h2 {
        font-size: 2rem;
    }

    .slider-content p {
        font-size: 12px;
    }

    .btn.slide-btn {
        padding: 10px 18px;
        font-size: 8px;
    }

}