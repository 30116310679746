#employers {
    position: relative;
}

#employers header {
    margin-bottom: 65rem;
}

#employers .head-content {
    margin-top: -165px;
}

@media (max-width: 991px) {

    #employers header {
        margin-bottom: 95rem;
    }

    #employers .head-content {
        margin-top: -120px;
    }
}

@media (max-width: 768px) {
    #employers .masthead {
        height: 400px !important;
    }

    #employers header {
        margin-bottom: 105rem;
    }

    #employers header p {
        font-size: 13px;
    }
}