.about {
    min-height: 40vh;
    padding: 2rem 0;
    position: relative;
    margin-bottom: 5rem;
    /* border: solid red 2px; */
}

.about .container .col .txtContent .header h1 {
    text-transform: uppercase;
    color: var(--sea-blue);
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.about .container .col .txtContent .txtDesc h4 {
    color: var(--dark-blue);
    font-size: 1.7rem;
}

.about .container .col .txtContent .btn {
    display: flex;
    justify-content: flex-end;
}

.about .container .right {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid black 2px; */
}

.about .container .right .contentWrapper {
    /* border: solid red 2px; */
    height: 18rem;
    width: 70%;
    position: relative;
    display: flex;
    place-items: center;
    left: 10%;
    /* cursor: pointer; */
    /* justify-content: center;
align-items: center;
right: 0; */
}

.about .container .right .aboutIllustr {
    height: 18rem;
    width: 70%;
    position: relative;
    display: flex;
    place-items: center;
}

.about .container .right .aboutIllustr .seaBlue {
    width: 25rem;
    height: 18.6rem;
    background-color: var(--sea-blue);
    position: absolute;
    top: -2rem;
    left: 2rem;
    transition: var(--transition);
}

.about .container .right .aboutIllustr .darkBlue {
    width: 25rem;
    height: 18.6rem;
    background-color: var(--dark-blue);
    position: absolute;
    top: -1rem;
    left: 1rem;
    transition: var(--transition);
}

.about .container .right .aboutIllustr:hover .seaBlue {
    top: -1.3rem;
    left: 1rem;
}

.about .container .right .aboutIllustr:hover .darkBlue {
    top: -2rem;
    left: 2rem;

}

.about .container .right .aboutIllustr img {
    width: 25rem;
    height: 18.6rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: var(--transition);
}

.about .container .right .aboutIllustr:hover img {
    top: -1.6rem;
    left: 1.6rem;
}

.about .txtDesc p {
    text-align: left;
}

.services {
    min-height: 100vh;
    background-color: var(--grey-color);
}

.services .container .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: solid red 2px; */
}

.services .container .top .header h1 {
    text-transform: uppercase;
    color: var(--sea-blue);
    font-size: 3rem;
}

.services .container .top .txtContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services .container .top .txtContent p {
    text-align: center;
}

.services .container .bottom {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    .about {
        margin-bottom: 2rem;

    }

    .about .container {
        flex-direction: column;
    }

    .about .container .col .txtContent .header h1 {
        font-size: 2rem;
        text-align: center;
    }

    .about .container .col .txtContent .txtDesc h4 {
        font-size: 1.375rem;
        text-align: center;
    }

    .about .txtDesc p {
        text-align: center;
    }

    .about .container .right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: solid black 2px; */
    }

    .about .container .right .contentWrapper {
        /* border: solid red 2px; */
        height: 18rem;
        width: 100%;
        position: relative;
        display: flex;
        place-items: center;
        left: 0;
        margin-top: 3rem;

    }

    .about .container .right .contentWrapper .seaBlue {
        width: 90%;
        height: 18.6rem;
        z-index: 1;
    }

    .about .container .right .contentWrapper .darkBlue {
        width: 90%;
        height: 18.6rem;
        z-index: 2;
    }



    .about .container .right .contentWrapper img {
        width: 90%;
        height: 18.6rem;
        position: relative;
        z-index: 3;
        object-fit: cover;
    }

    .services .container .top .header h1 {
        font-size: 2rem;
        text-align: center;
    }


    .services .container .top .txtContent p {
        text-align: center;
    }

    .services .container .bottom {
        margin-bottom: 6rem;
    }
}