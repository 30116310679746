@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../node_modules/react-modal-video/scss/modal-video.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --dark-blue: #133860;
  --darker-blue: #0c031b;
  --sea-blue: #58bee1;
  --color-white: #fff;
  --grey-light: #f0eff2;
  --color-light: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;
  --light-color: rgba(#fff, 0.7);
  --black-color: #0c031b;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
  --grey-color: #f6f6f6;
  --yellow-color: rgb(210, 180, 73);
}

#root {
  white-space: pre-line;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: sans-serif, "poppins";
  line-height: 1.5;
  color: var(--dark-color);

  background: var(--color-white);
  user-select: none;
  overflow-x: hidden;
  position: relative;
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-family: poppins;
}

section h2 {
  text-align: center;
}

section p {
  text-align: center;
  width: 100%;
  margin: 0.6rem auto 2.5rem;
  text-align: left;
}

.btn-sea {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  background: var(--sea-blue);
  color: var(--dark-blue);
  font-weight: 500;
  /* letter-spacing: 0.2rem; */
  cursor: pointer;
  transition: all 400ms ease;
  border: 0;
  text-decoration: none;
}

.btn-sea:hover {
  background: lighten(#58bee1, 8%);
  color: var(--dark-blue);
  box-shadow: 0 1rem 1.6rem rgba(#58bee1, 0.15);
  transform: scale(0.94);
}

.btn-dark {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  background: var(--dark-blue);
  color: var(--color-white);
  font-weight: 500;
  /* letter-spacing: 0.2rem; */
  cursor: pointer;
  transition: all 400ms ease;
  border: 0;
  text-decoration: none;
}

.btn-dark:hover {
  background: lighten(#133860, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#133860, 0.15);
  transform: scale(0.94);
}

.video__overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 165, 75, 0.1);
  background-image: linear-gradient(transparent, #133860);
  z-index: 2;
}

#hero-bgVid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.form {
  /* display: flex;
flex-direction: column;
justify-content: center;
align-items: center; */
  width: 70%;
  padding: 0 5%;
  margin-bottom: 5rem;
}

.form form .form__group {
  display: flex;
  gap: 4%;
  margin: 1rem 0;
}

.form form .form__group>div {
  width: 48%;
}

.form form .form__group .full {
  width: 100%;
}

.form form .form__group label {
  margin-top: 1rem;
  /* color: var(--light-color); */
}

.form form .form__group input {
  border: solid var(--grey-light) 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--grey-light);
  margin-top: 0.6rem;
}

.form form .form__group textarea {
  border: solid var(--grey-light) 1px;
  width: 100%;
  height: 10rem;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--grey-light);
  margin-top: 0.6rem;
}

.form form .form__group input[type="submit"] {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  background: var(--dark-blue);
  color: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: all 400ms ease;
  border: 0;
}

.form form .form__group select {
  border: solid var(--grey-light) 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--grey-light);
  margin-top: 0.6rem;
}

.btn.resume-btn {
  border-radius: 50px;
  color: var(--black-color);
  background-color: var(--sea-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 145.5%;
  padding: 20px 40px 20px;
}

.btn.resume-btn:hover {
  background-color: var(--dark-blue);
  color: var(--color-white);
}

.btn.resume-btn-dark {
  border-radius: 50px;
  color: var(--color-white);
  background-color: var(--dark-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 145.5%;
  padding: 20px 40px 20px;
}

.btn.resume-btn-dark:hover {
  background-color: var(--darker-blue);
  color: var(--color-white);
}

.form-control:focus,
.form-select:focus {
  border-color: var(--dark-blue) !important;
  box-shadow: unset !important;
}

.btn.form-btn-light {
  border: 1px solid var(--dark-blue);
  color: var(--dark-blue);
}

.btn.form-btn-light:hover {
  background-color: var(--dark-blue);
  color: #fff;
}

.btn.form-btn-dark {
  background-color: var(--black-color);
  color: #fff;
}

.btn.form-btn-dark:hover {
  background-color: var(--dark-blue);
  color: #fff;
}

.text-default {
  color: var(--black-color) !important;
}

.bg-dark-blue {
  background-color: var(--dark-blue);
  color: var(--color-white);
}

.bg-grey {
  background-color: var(--grey-color);
}

.btn.scrollTop {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--dark-blue);
  color: var(--color-white);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 400ms ease;
  z-index: 1000;
}

.btn.scrollTop:hover {
  background-color: var(--sea-blue);
}

.modal-video {
  background-color: rgba(0, 0, 0, 0.5);
}

.text-dark-blue {}

.react-quiz-container {
  max-width: 70vw !important;
}

.react-quiz-container .selection-tag.single {
  display: none;
}

.react-quiz-container .number-of-selection {
  margin-left: 0 !important;
}

.react-quiz-container .questionBtnContainer .btn.nextQuestionBtn {
  background-color: var(--dark-blue) !important;
  border-radius: 20px !important;
  color: white !important;
  padding: 10px 20px !important;
}

.react-quiz-container .questionBtnContainer .btn.nextQuestionBtn:hover {
  background-color: var(--black-color) !important;
}

.react-quiz-container .questionWrapper .btn:disabled {
  color: red !important;
  text-decoration: line-through;
}

.popup-box {
  z-index: 10000 !important;
}

.popup-box .box {
  width: 50% !important;
}

.popup-box .close-icon {
  right: calc(26% - 30px) !important;
}

.popup-box .box p {
  font-size: 18px;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .btn.resume-btn {
    padding: 10px 30px 10px;
    font-size: 13px;
  }

  .popup-box .box {
    width: 80% !important;
  }

  .popup-box .close-icon {
    right: calc(12% - 30px) !important;
  }

  .popup-box .box h2 {
    font-size: 1.3rem !important;
  }

  .popup-box .box p,
  .popup-box .box form label,
  .popup-box .box form input,
  .popup-box .box form button.btn {
    font-size: 15px !important;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .form form .form__group {
    flex-direction: column;
  }

  .form form .form__group>div {
    width: 100%;
  }

  .form form .form__group input {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .popup-box .close-icon {
    right: calc(15% - 30px) !important;
  }

  .popup-box .box h2 {
    font-size: 1.2rem !important;
  }

  .popup-box .box p,
  .popup-box .box form label,
  .popup-box .box form input,
  .popup-box .box form button.btn {
    font-size: 12px !important;
  }

}