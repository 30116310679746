.contactPageSection {
    background-color: var(--color-white);
    min-height: 80vh;
    /* padding-top: 8rem; */
    padding-bottom: 10rem;
    position: relative;
    /* z-index: -2; */
}

.contactPageSection .container {
    z-index: 2;
}

.contactPageSection .container .contactPageHeader {
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.contactPageSection .container .contactPageHeader h1 {
    font-style: normal;
    font-weight: 200;
    font-size: 64px;
    text-transform: capitalize;
    line-height: 4.5rem;
    color: var(--dark-blue);
}

.contactPageSection .container .map {
    filter: saturate(0);
}

.contactPageSection .container .map:hover {
    filter: unset;
}

.contactPageSection .container .addressForm {
    display: flex;
    gap: 5%;
    margin-top: 2rem;
}

.contactPageSection .container .addressForm .address {
    width: 50%;
}

.contactPageSection .container .addressForm .contactForm {
    width: 50%;
}

.contactPageSection .container .addressForm .contactForm form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contactPageSection input,
.contactPageSection textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid #999;
    resize: none;
    color: var(--dark-blue);
}

.contactPageSection .container .addressForm .contactForm form button {
    z-index: 2;
}

#contact-us .head-content {
    width: 55% !important;
}

#contact-us .head-content a,
#contact-us .head-content p {
    font-size: 17px !important;
}

#contact-us .form-and-hours {
    height: fit-content;
    background-image: url(../../assets/images/banner-section.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 0;
}

#contact-us form {
    background: white;
    max-width: 100%;
}

#contact-us .work-hours p {
    font-size: 20px;
    font-weight: 500;
}


/* =========== Media Queries (medium Screens) ========== */
@media screen and (max-width:1024px) {
    #contact-us .head-content {
        width: 80% !important;
    }
}


/* =========== Media Queries (Small Screens) ========== */

@media screen and (max-width:600px) {
    .contactPageSection .container .contactPageHeader h1 {
        font-size: 44px;
        line-height: 2.5rem;
    }

    .contactPageSection .container .addressForm {
        flex-direction: column;
    }

    .contactPageSection .container .addressForm .address {
        width: 100%;
        margin-bottom: 2rem;
    }

    .contactPageSection .container .addressForm .contactForm {
        width: 100%;
    }

    .contactPageSection .container .addressForm .contactForm .btn-dark {
        width: 100%;
    }

    #contact-us .form-and-hours {
        padding: 30px 0;
    }
}