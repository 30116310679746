.card .top .card-img {
    width: 100%;
    height: 22rem;
    background-color: var(--dark-blue);
    position: relative;
    /* border: solid blue 2px; */
}

.card .top .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .top:hover .card-img img {
    filter: grayscale(80%) brightness(100%) opacity(.7);

    transition: var(--transition);
}

.card .top .cardOverlay {
    width: 100%;
    height: 22rem;
    position: absolute;
    padding: 5%;
    visibility: hidden;
    background-color: rgba(19, 56, 96, 0.6);
    /* border: solid blue 2px; */
}

.card .top:hover .cardOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    transition: var(--transition);
}

.card .top .cardOverlay .details {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* border: solid white 3px; */
    padding: 2%;
    border-radius: 1.5rem;
    text-align: center;
}

.card .top .cardOverlay .details p {
    color: var(--color-white);
    /* border: solid white 2px; */
    text-align: center;
}

.card .cardText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    /* border: solid black 2px; */
}

.card .cardText h4 {
    color: var(--dark-blue);
    text-transform: capitalize;
    text-align: center;
    font-weight: 500;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    .cardsWrapper {
        display: grid;
        /* grid-template-columns: repeat(1, 1fr); */
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1%;
        margin-bottom: 1rem;
    }

    .cardsWrapper .card {
        /* border: solid red 2px; */
        width: 100%;
        margin-bottom: 1rem;
    }

}