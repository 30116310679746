#employeeForm .form-section {
    position: absolute;
    top: 60%;
    z-index: 1;
    left: 0;
    right: 0;
}

#employeeForm form {
    background: white;
    max-width: 100%;
}

#employeeForm .form-container {
    padding: 0 110px;
}

@media (max-width: 991px) {
    #employeeForm .form-container {
        padding: 0;
    }

    #employeeForm header {
        margin-bottom: 95rem;
    }

    #employeeForm .head-content {
        margin-top: -120px;
    }
}

@media (max-width: 768px) {
    #employeeForm .masthead {
        height: 400px !important;
    }

    #employeeForm header {
        margin-bottom: 105rem;
    }

    #employeeForm header p {
        font-size: 13px;
    }
}