.textContentWrapper {
    min-height: 60vh;
    position: relative;
}

.textContentWrapper .container .header h1 {
    color: var(--dark-blue);
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 200;
}

.textContentWrapper .container .txtBody article {
    color: var(--dark-blue);
}

.textContentWrapper .container .txtBody article ul {
    margin-left: 5%;
}

.textContentWrapper .container .txtBody article ul li {
    list-style-type: disc;
    margin-bottom: 1rem;
}