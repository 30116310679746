#register-course {
    position: relative;
}

#register-course header {
    margin-bottom: 55rem;
}

#register-course .head-content {
    margin-top: -165px;
}

#register-course .form-section {
    position: absolute;
    top: 60%;
    z-index: 1;
    left: 0;
    right: 0;
}

#register-course form {
    background: white;
    max-width: 100%;
}

#register-course .form-container {
    padding: 0 110px;
}

@media (max-width: 991px) {
    #register-course .form-container {
        padding: 0;
    }

    #register-course header {
        margin-bottom: 65rem;
    }

    #register-course .head-content {
        margin-top: -120px;
    }
}

@media (max-width: 768px) {
    #register-course .masthead {
        height: 400px !important;
    }

    #register-course header {
        margin-bottom: 75rem;
    }
}