.course-content {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 880px !important;
}

.lesson.active {
    background-color: var(--sea-blue) !important;
    color: white;
    border: none !important;
}

.lesson:hover {
    background-color: var(--sea-blue) !important;
    color: var(--dark-blue);
    cursor: pointer;
}

.course-detail ol li {
    list-style: auto !important;
}

.tag {
    background-color: #F6F6F6 !important;
    padding: 13px 25px;
    border-radius: 24px;
}

.instructor-img {
    width: 135px;
    height: 135px;
    border-radius: 50%;
}

.score-section>div>p {
    text-align: center !important;
    font-size: 18px !important;
}